.portfolio-links {
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: stretch;
	align-content: stretch;
	padding: 3em;
	.portfolio-item {
		padding: 0;
		border-radius: $border-radius;
		text-shadow: 2px 2px 2px rgba(0,0,0,.1);
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		width: 46%;
		margin: 2%;
		text-decoration: none !important;
  		transition: box-shadow 0.2s ease-in-out, background 0.1s ease-in-out;
  		box-shadow: inset 0 0 20px 20px rgba(0,0,0,0), 2px 2px 12px 2px rgba(0,0,0,.2);
		background: rgb(87,74,133);
		&:hover {
			box-shadow: inset 0 0 20px 20px rgba(0,0,0,.05), 2px 2px 12px 2px rgba(0,0,0,0);
			background: $blue-300;
			// background: radial-gradient(circle, rgba(171,165,241,1) 0%, rgba(140,133,224,1) 100%);
		}
		&:active {
			box-shadow: inset 0 0 20px 20px rgba(0,0,0,.1), 2px 2px 12px 2px rgba(0,0,0,0);
		}
		.info {
			padding: 2em 1em;
			margin: 0;
			display: flex;
			.tags {
				display: block;
				text-shadow: none;
				align-self: center;
				order: -1;
				padding-right: 2em;
				li {
					list-style-type: none;
					display: inline-block;
					padding: .5em 1em;
					background: rgba(255,255,255,.9);
					font-size: .8em;
					text-transform: uppercase;
					border-radius: $border-radius; 
					margin-right: .25em;
					margin-bottom: .5em;
					font-weight: 400;
					letter-spacing: 1px;
					color: $gray-700;
					font-weight: bold;
					&:last-of-type {
						margin-right: 0;
					}
					&:first-of-type {
						font-size: 1.4em;
						background: #fff;
						border-radius: 2px;
    					margin-left: -1em;
    					box-shadow: 2px 2px 6px 2px rgba(0,0,0,.2);
					}
				}
			}
			h3 {
				font-family: $font-1;
				font-weight: 400;
				font-size: 1.6em;
				color: #fff;
				margin: .5em 0;
			}
		}
		@include mq($until: wide) {
			width: 48%;
			margin: 1%;
		}
		@include mq($until: desktop) {
			width: 94%;
			margin: 3% 3% 0 3%;
		}
		@include mq($until: mobile) {
			.info {
				padding: 2em 1em;
				font-size: .9em;
				flex-direction: column;
				.tags {
					padding-right: 0;
				}
			}
		}
	}
	@include mq($until: wide) {
		padding: 1em;
	}
	@include mq($until: desktop) {
		padding: 0;
		padding-bottom: 1em;
	}
}

.portfolio-piece {
	@extend .container;
	padding-bottom: 2em;
	img {
		max-width: 1200px;
		width: 98%;
		margin: 0 auto;
		display: block;
		padding-top: 1em;
	}
	a.back {
		display: block;
		margin: 10px auto;
		text-decoration: none;
		text-align: center;
	}
	h1 {
		font-family: $font-1;
		font-size: 2.5em;
		line-height: 1;
		text-align: center;
		margin: 0;
		padding: 0;
		@include mq($until: mobile) {
			font-size: 2.1em;
		}
	}
	h2 {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 1.2em;
		width: 96%;
		max-width: 700px;
		margin: 3em auto 0 auto;
		padding: 0;
		color: $black;
	}
	p, ol, ul {
		text-align: left;
		font-size: 1.3em;
		line-height: 1.5;
		max-width: 700px;
		width: 96%;
		margin: 0 auto;
		padding: 1em 0 0;
		@include mq($until: mobile) {
			font-size: 1em;
		}
		li {
			margin-left: 2em;
			width: 80%;
		}
		&.lead {
			font-size: 1.5em;
			line-height: 1.4;
			@include mq($until: mobile) {
				font-size: 1.2em;
			}
		}
		&.quote {
			font-family: $font-1;
			font-size: 1.6em;
			text-align: center;
			padding-top: 1.5em;
			line-height: 1.2;
			max-width: 650px;
			font-weight: 600;
		}
		&.author {
			padding-top: .5em;
			padding-bottom: .5em;
			color: $green-700;
			text-align: center;
			max-width: 650px;
			font-size: 1em;
		}
	}
}