.feature-callout-container {
	background: $gray-700;
}
.feature-callout {
	padding: 75px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	.image-caption {
		position: absolute;
		bottom: 10%;
		padding: 10px;
		background: #fff;
		right: 0;
		color: $gray-600;
		font-weight: 400;
		span.strong {
			color: $gray-600;
			font-size: 1.1em;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
	}
	&.white-bg {
		background: #fff;
		.text p {
			color: $black;
		}
	}
	&.less-pad {
		padding: 40px 0 0;
	}
	.text h2 {
		font-family: $font-1;
		font-weight: 800;
		font-size: 2.8em;
		margin-top: .1em;
		margin-bottom: .1em;
	}
	.text p {
		font-family: $font-1;
		font-weight: 400;
		font-size: 2em;
		color: #fff;
		margin: .1em;
		margin-right: 1em;
		a, a:visited {
			color: #fff;
			text-decoration: none;
			border-bottom: 1px solid $green-600;
			display: inline-block;
			font-size: .6em;
			margin-top: .6em;
			&:hover, &:active, &:focus {
				border-bottom: 4px solid $green-600;
			}
		}
	}
	.image {
		img {
			width: 100%;
		}
		@include mq($from: desktop) {
			margin-bottom: -30px;
		}
	}
	@include mq($from: tablet, $until: desktop) {
		padding: 40px 0 0;
		&.less-pad {
			padding: 0 0 10px 0;
		}
		.text {
			h2 {
				font-size: 2.2em;
			}
			p {
				font-size: 1.7em;
				margin: 0;
			}
		}
		.image {
			padding: 20px 20px 5px 20px;
		}
	}
	@include mq($until: tablet) {
		flex-wrap: wrap;
		padding: 40px 0 0;
		&.less-pad {
			padding: 0 0 10px 0;
		}
		.text {
			h2 {
				font-size: 2em;
			}
			p {
				font-size: 1.5em;
				margin: 0;
			}
		}
		.image {
			padding: 20px 20px 5px 20px;
		}
	}
}
.feature-title {
	width: 400px;
	max-width: 90%;
	font-size: 3.1em;
	span {
		font-family: $header-font;
		font-weight: 800;
		letter-spacing: .2px;
		color: $black;
		line-height: .9;
	}
	@include mq($from: tablet, $until: wide) {
		width: 400px;
		font-size: 2.7em;
	}
	@include mq($until: tablet) {
		width: 400px;
		font-size: 1.8em;
	}
}
.feature-body {
	font-size: 1.2rem;
	font-family: $body-font;
	font-weight: 400;
	line-height: 1.3;
	margin-bottom: 0;
}
.feature-link {
	margin-top: 10px;
	display: block;
	font-size: 1.1em;
	font-weight: 600;
}
.feature-computer {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 45%;
	z-index: 10;
	@include mq($from:mobile, $until:tablet) {
		width: 44%;
		right: 10px;
		top: 0;	
	}
	@include mq($until: mobile) {
		top: 0px;
		right: 10px;
		height: 100px;
		width: auto;
	}
}
.feature-iphone {
	position: absolute;
	right: 40%;
	top: 200px;
	width: 13%;
	z-index: 15;
	@include mq($from:tablet, $until:wide) {
		width: 10%;
		right: 38%;
	}
	@include mq($from:mobile, $until:tablet) {
		width: 14%;
		right: 25%;
	}
	@include mq($until: mobile) {
		top: 40px;
		right: -10px;
		height: 100px;
		width: auto;
	}
}