header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	z-index: 1000;
	position: relative;
	border-top: 5px solid $green-400;
	height: 80px;
	&.homepage {
		border-top: 0 solid $green-400;
		@include mq($from: desktop) {
			height: 175px;
			.logo-wrapper {
				width: 300px;
			}
		}
		.items-wrapper {
			max-width: $container-size;
		}
	}
	@include mq($from:tablet) {
		.menu-button {
			display: none;
		}
	}
	@include mq($until:tablet) {
		height: auto;
		width: 100%;
		padding: 20px 0 20px 0;
		.menu-button {
			display: block;
			cursor: pointer;
			width: 70px;
		}
	}
	@include mq($until:tiny) {
		.menu-button {
			width: 60px;
		}
	}
	.items-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 90%;
		@include mq($until:tablet) {
			align-items: center;
			flex-wrap: wrap;
			width: 90%;
		}
	}
	.mark-paths {
		display: none;
	}
	.logo-wrapper {
		width: 200px;
		align-self: center;
		@include mq($until: tablet) {
			display: block;
			margin-right: auto;
			img {
				width: 60%;
				margin: 0 auto;
			}
		}
		@include mq($until: tiny) {
			width: 170px;
		}
	}
	nav.navigation {
		text-transform: uppercase;
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 1em;
		ul {
			li {
				list-style-type: none;
				display: inline-block;
				a {
					text-decoration: none;
					display: block;
					margin-right: 2em;
					color: $gray-600;
					font-weight: 400;
					letter-spacing: 1px;
					font-size: .9em;
					background: linear-gradient(to right, $green-400 50%, transparent 50%);
					background-size: 300% 100%;
					background-position: right bottom;
					transition: all .2s ease-out;

					&:hover, &.active {
					background-position: left bottom;
					color: $gray-700;
					}

					@include mq($until: wide) {
						margin-right: 1em;
					}
					@include mq($until: desktop) {
						margin-right: 1em;
					}
					&.cta {
						background: $green-600;
						color: #fff;
						padding: 10px 20px;
						border-radius: $border-radius;
						margin-right: 0;
					}
				}
			}
		}
		@include mq($until: tablet) {
			display: none;
			text-align: center;
			justify-content: center;
			width: 80%;
		    position: absolute;
		    top: 50px;
		    left: 5%;
		    background: white;
		    margin: 0;
		    padding: 5%;
		    padding-bottom: 20px;
		    box-shadow: 0 10px 20px 2px rgba(0,0,0,.2);
			ul {
				li {
					display: block;
					margin-top: 20px;
					a {
						margin-right: 0;
					}
				}
			}
		}
	}
}