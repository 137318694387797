.section-title {
	font-weight: 700;
	font-family: $body-font;
	color: #fff;
	width: 90%;
	padding: 30px 0;
	margin: 0 auto;
	text-align: center;
	font-size: 2em;
	span {
		color: #fff;
		font-size: .6em;
	}
}
.small-container {
	max-width: $small-container-size;
	width: 90%;
	margin: 0 auto;
	@include mq($from: ultra) {
		max-width: $container-size;
		width: 90%;
	}
}
.full-width-mobile-container {
	@include mq($until: mobile) {
		width: 100%;
	}
}
.container {
	max-width: 1000px;
	width: 90%;
	margin: 0 auto;
	@include mq($from: ultra) {
		max-width: 1400px;
		width: 90%;
	}
	&.worked-on {
		margin-top: 20px;
		margin-bottom: 20px;
		img {
			max-width: 100%;
			margin: 0 auto;
		}
		.mobile {
			display: block;
		}
		.desktop {
			display: none;
		}
		@include mq($from: desktop) {
			img {
				max-width: 80%;
				margin: 0 auto;
			}
			.desktop {
				display: block;
			}
			.mobile {
				display: none;
			}
		}
	}
}
.wide-container {
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	@include mq($from: tablet) {
		width: 90%;
	}
	@include mq($from: ultra) {
		max-width: 1400px;
		width: 90%;
	}
}

.text-shadow {
  text-shadow:
    -2px -2px #fff,
    -2px 2px #fff,
    2px -2px #fff,
    2px 2px #fff;
}

.underline {
  background-size: 1px 1em;
  box-shadow:
    inset 0 -0.175em white,
    inset 0 -0.2em #000;
  display: inline;
}

.underline--green {
    box-shadow:
    inset 0 -0.12em white, inset 0 -0.2em $green-400;
}

.homepage-white-container {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right center;
	display: block;
	margin: 0;
	width: 100%;
	z-index: 99;
	@include mq($until:tablet) {
		padding: 10px 0;
	}
	h1 {
		margin: 0 auto;
		padding: 0;
		text-align: center;
		width: 96%;
		@include mq($from:mobile) {
			width: 80%;
		}
	}
}
.homepage-pretitle {
	font-size: 1em;
	text-transform: uppercase;
	color: $green-600;
	font-weight: 700;
	text-align: center;
	display: block;
	width: 100%;
	@include mq($until: tablet) {
		font-size: .5em;
	}
}
.homepage-title {
	font-family: $header-font;
	font-weight: 800;
	font-size: 1em;
	margin-top: -.05em;
	letter-spacing: .2px;
	display: inline-block;
  	white-space: nowrap;
	color: $black;
	.kerning {
		font-family: $header-font;
		letter-spacing: 0em;
	}
	@include mq($until: tablet) {
		font-size: 1.1em;
		margin-top: 5px;
		margin-bottom: 10px;
		line-height: .9;
  		white-space: pre-wrap;
	}
} 
.homepage-list-container {
	display: flex;
	@include mq($until:tablet) {
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
	}
}
.homepage-services-list {
	display: block;
	font-size: 1.3em;
	font-weight: 400;
	margin: 20px auto;
	text-align: center;
	li {
		list-style-type: none;
		display: block;
		margin: 0 auto 10px auto;
		a {
			color: $gray-600;
		}
		@include mq($from: tablet) {
			display: inline-block;
			margin-right: .5em;
			&:last-of-type {
				margin-right: 0;
			}
		}
	}
	@include mq($until: tablet) {
		font-size: 1.1em;
		margin: 20px auto 30px auto;
	}
}