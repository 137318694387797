footer {
	background: $black;
	color: #fff;
	padding: 5em 0;
	font-size: 1.2em;
	@include mq($until: tablet) {
		padding: 2em 0;
	}
	.links {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		@include mq($until: tablet) {
			flex-wrap: wrap;
			text-align: center;
			.list {
				flex-basis: 100%;
				padding: 1em;
			}
		}
		li {
			list-style-type: none;
			color: #fff;
			margin-bottom: 10px;
			&.title {
				font-family: $font-1;
				font-size: 1.1em;
				margin-bottom: 15px;
			}
			a {
				text-decoration: none;
				color: #fff;
				transition: all .1s;
				&:hover {
					color: $green-600;
					text-decoration: underline;
				}
			}
		}
	}
	.copy {
		text-align: center;
		padding-top: 2em;
		color: $gray-300;
	}
	.contact {
		text-align: center;
		padding-top: 2em;
		color: #fff;
		font-weight: bold;
		display: flex;
		justify-content: center;
		@include mq($until: mobile) {
			display: block;
			text-align: center;
		}
		a {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			color: #fff;
			margin-right: 20px;
			@include mq($until: mobile) {
				margin: 0;
				padding-top: .5em;
				display: flex;
			}
			&:last-of-type {
				margin-right: 0;
			}
			svg {
				margin-right: 5px;
			}
		}
	}
}