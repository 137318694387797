@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400,600,800&Source+Sans+Pro:400,700&display=swap');

@import 'base/variables';

@import 'tools/normalize';
@import 'tools/mq';

@import 'base/fonts';
@import 'base/links';
@import 'base/base';

@import 'components/type';
@import 'components/header';
@import 'components/page';
@import 'components/image-feature';
@import 'components/image-header';
@import 'components/emblem';
@import 'components/gradient-background';

@import 'pages/homepage-form';
@import 'pages/homepage-animations';
@import 'pages/homepage-case-studies';

@import 'pages/about-overview';
@import 'pages/about-targets';
@import 'pages/about-ankur';

@import 'pages/services';
@import 'pages/portfolio';
@import 'pages/contact';

@import 'sections/footer';


// @import 'components/cards';

#fake-content {
  width: 100%;
  height: 5000px;
  background: #fff;
}