.service {
	margin: 2em auto;
	vertical-align: top;
	background-repeat: no-repeat;
	background-size: 40% auto;
	background-position: 101% 0%;
	box-shadow: inset 0 0 20px 5px transparentize($gray-200, .8);
	position: relative;
	@include mq($until: mobile) {
		margin: 0 auto;
	}
	&:first-of-type {
		margin-top: 2em;
	}
	.anchor {
		position: absolute;
		top: -80px;
		left: 0;
		height: 0;
		width: 0;
	}
	.service-border {
		padding: 2em;
		border: 2px solid $gray-300;
		border-radius: 2px;
		@include mq($until: mobile) {
			padding: 1em;
		}	
	}
	.line {
		width: 50px;
		height: 5px;
		margin: 1em 0;
		background: $gray-600;
	}
	h2 {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 1.8em;
		margin: .25em 0;
		color: $gray-600;
		@include mq($until: mobile) {
			font-size: 1.4em;
		}
	}
	p {
		font-size: 1.4em;
		line-height: 1.4;
		max-width: 800px;
		@include mq($until: mobile) {
			font-size: 1.1em;
			line-height: 1.3;
		}
	}
	li {
		list-style-position: inside; 
		font-size: 1.4em;
		line-height: 1.3;
		@include mq($until: mobile) {
			font-size: 1.1em;
		}		
	}
}