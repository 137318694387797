.fast-facts {
	padding-bottom: 3em;
	h3 {
		// text-transform: uppercase;
		font-weight: 400;
		font-size: 1.5em;
		color: $gray-500;
		margin: 0;
		padding: 0;
		span {
			font-weight: 700;
			color: $black;
		}
	}
	p {
		margin: 0;
		padding: .5em 0;
		line-height: 1.3;
		font-size: 1.3em;
		width: 500px;
		max-width: 100%;
	}
	.fact-container {
		width: 33%;
		display: inline-block;
		vertical-align: top;
		@include mq($from: mobile, $until: desktop) {
			width: 49.5%;
		}
		@include mq($until: mobile) {
			width: 100%;
		}
		.fact {
			padding: 0;
			.title {
				text-transform: uppercase;
				font-weight: 700;
				font-size: 1.2em;
				margin: 1.8em 0 .5em 0;
				color: $gray-500;
				@include mq($until: mobile) {
					margin: 1em 0 .5em 0;
				}
			}
			.body {
				line-height: 1.3;
				font-size: 1.2em;
				width: 90%;
			}
		}
	}
}