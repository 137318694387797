* {
	color: $black;
}

::selection {
  background: $green-400; /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: $green-400; /* Gecko Browsers */
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.hide-small-screen {
	@include mq($until: mobile) {
		display: none;
	}
	@include mq($from: mobile) {
		display: block;
	}
}

.hide-big-screen {
	@include mq($until: mobile) {
		display: block;
	}
	@include mq($from: mobile) {
		display: none;
	}
}

.animated-in {
	opacity:  0;
}