.page-image-header {
	img {
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
		display: block;
	}
	h1 {
		font-family: $font-1;
		font-size: 3em;
		text-align: center;
		margin: 0;
		padding: 0;
		@include mq($until: mobile) {
			font-size: 2.1em;
		}
	}
	p {
		text-align: center;
		font-size: 1.3em;
		line-height: 1.3;
		max-width: 700px;
		width: 90%;
		margin: 0 auto;
		padding: .5em 0;
		@include mq($until: mobile) {
			font-size: 1em;
		}
	}
}