a, a:visited, a:active {
	transition: all .1s;
	&:hover {
		color: $green-600;
	}
	&.cta {
		background: $green-600;
		color: #fff;
		padding: 10px 20px;
		border-radius: $border-radius;
		margin-right: 0;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 1px;
		display: inline-block;
		&:hover {
			background: darken($green-600, 10%);
		}
		&:focus, &:active {
			background: darken($green-600, 20%);
		}
	}
}