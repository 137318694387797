.gradient-background.blue {
	background: rgb(159,206,255);
	background: radial-gradient(circle, rgba(159,206,255,1) 0%, rgba(101,164,231,1) 100%);
}
.gradient-background.orange {
	background: rgb(255,189,138);
	background: radial-gradient(circle, rgba(255,189,138,1) 0%, rgba(230,156,99,1) 100%);
}
.gradient-background.purple {
	background: rgb(171,165,241);
	background: radial-gradient(circle, rgba(171,165,241,1) 0%, rgba(140,133,224,1) 100%);
}
.gradient-background.dark-purple {
	background: rgb(87,74,133);
	background: radial-gradient(circle, rgba(87,74,133,1) 0%, rgba(78,70,108,1) 100%);
}