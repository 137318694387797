/**
 *
 * COLORS
 *
 */
$green-100:#F2FFED;
$green-200:#D5F8C3;
$green-300:#BAF89B;
$green-400:#A3EC7F;
$green-500:#90D46E;
$green-600:#72BF56;
$green-700:#429E42;
$green-800:#277627;
$green-900:#1D581D;

$gray-100:#F4F4F6;
$gray-200:#D1CFD8;
$gray-300:#9592A0;
$gray-400:#9592A0;
$gray-500:#6F6C7A;
$gray-600:#494653;
$gray-700:#3A3649;
$gray-800:#2D293D;
$gray-900:#1C1B20;

$blue-100:#E0D4F7;
$blue-200:#B6ABED;
$blue-300:#8C85E0;
$blue-400:#8181E4;
$blue-500:#4747D1;
$blue-600:#3232B9;
$blue-700:#2929A3;
$blue-800:#1F2E7A;
$blue-900:#121D54;

$red-100:#FBE9E9;
$red-200:#FBD0D0;
$red-300:#F58989;
$red-400:#F07575;
$red-500:#EC6071;
$red-600:#DD3C57;
$red-700:#AD1F36;
$red-800:#730D0D;
$red-900:#450808;

$black: $gray-900;

/**
 *
 * CSS DEFAULTS
 *
 */
$border-radius: 6px;

$small-container-size: 700px;
$container-size: 1000px;
$big-container-size: 1200px;

$font-1: 'EB Garamond', serif;
$font-2: 'Open Sans', sans-serif;

$mq-breakpoints: (
    tiny:  420px,
    mobile:  520px,
    tablet:  800px,
    desktop: 980px,
    wide:    1300px,
    ultra: 1600px
) !default;