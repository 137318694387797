$form-padding-lg: 4em 5em;
.form-container-container {
	position: relative;
	background: #fff;
	background: linear-gradient(180deg, #fff 0%, #fff 120px, $gray-700 120px, $gray-700 100%);
	overflow: hidden;
	@include mq($from: tablet, $until: desktop) {
		background: linear-gradient(180deg, #fff 0%, #fff 60px, $gray-700 60px, $gray-700 100%);
	}
	@include mq($until: tablet) {
		background: $gray-700;
	}
}
.homepage-form-container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: $gray-100;
	color: #fff;
	width: 94%;
	max-width: $small-container-size;
	margin: 0 auto;
	z-index: 999;
	position: relative;
	border-radius: $border-radius $border-radius;
	padding: $form-padding-lg;
	.text {
		max-width: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		h2 {
			font-family: $font-2;
			font-size: 1.5em;
			line-height: 1.1;
			margin: 0;
			padding: 0;
			margin-bottom: 10px;
		}
		font-size: 1.25em;
		line-height: 1.3;
	}
	.direct-contact {
		margin-top: 1em;
		font-size: .85em;
		font-weight: bold;
		.info {
			font-size: .9em;
			font-weight: normal;
			margin: 0;
			padding: 0;
			li {
				padding: 1px 0px;
				list-style-type: none;
			}
		}
	}
	form {
		width: auto;
		margin: 0;
		padding: 0 0 0 2em;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-around;
		position: relative;
		.message {
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			box-shadow: 0 -2px 8px 2px rgba(0,0,0,.01);
			background: $green-100;
			display: none;
			align-items: center;
			justify-content: center;
			padding: 0;
			color: $black;
			font-size: 1.4em;
			text-align: center;
			border-radius: $border-radius;
		}
		label {
			display: block;
			width: 100%;
			text-transform: uppercase;
			color: $gray-700;
			font-size: .8em;
			font-weight: 700;
			line-height: 2;
			letter-spacing: 1px;
			margin-bottom: 5px;
		}
		input, textarea, select {
			padding: 12px 15px;
			border: 1px solid $gray-200;
			width: 100%;
			max-width: 500px;
			display: block;
			border-radius: $border-radius;
			font-size: 1.1em;
			outline: none;
			&:hover {
				box-shadow: 0 0 2px 2px rgba(0,0,0,.1);
				cursor: pointer;
			}
			&:focus {
			  box-shadow: 0 0 5px $green-600;
			  border: 1px solid $green-600;
			}
			@include mq($until:tablet) {
				font-size: 16px;
			}
		}
		textarea {
			align-self: stretch;
			min-height: 100px;
		}
		button {
			background: $green-600;
			color: #fff;
			padding: 10px 20px;
			margin: 10px 0;
			border-radius: $border-radius;
			margin-right: 0;
			text-decoration: none;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 1px;
			display: block;
			border: none;
			&:hover {
				cursor: pointer;
				background: darken($green-600, 10%);
			}
			&:focus, &:active {
				background: darken($green-600, 20%);
			}
			&:disabled {
				background: $gray-400;
				cursor: not-allowed;
				color: $gray-600;
			}
		}
	}
	@include mq($until: tablet) {
		width: auto;
		padding: 30px;
		flex-wrap: wrap;
		font-size: .9em;
		border-radius: 0;

		.text {
			max-width: auto;
		}
		div {
			margin: 0;
			width: 100%;
		}
		form {
			padding: 1em 0 0;
		}
		form button {
			align-self: flex-end;
			font-size: 1.1em;
			width: 100%;
			margin: 10px 0 0;
		}
	}
	@include mq($from:mobile, $until: tablet) {
		.text {
			max-width: 200px;
		}
		form {
			padding: 0 0 0 1em;
		}
	}
}