.who-we-help {
	margin: 0 auto;
	padding: 0;
	position: relative;
	background: $gray-700;
	padding: 40px 0;
	@include mq($until: mobile) {
		padding: 0;
	}
	.full-width-container {
   		display:-ms-grid;
		display: grid;
	    -ms-grid-columns: max-content 1fr;
	    grid-template-columns: max-content 1fr;
	    row-gap:30px;
	    align-items: center;
	    column-gap:20px;
		max-width: $container-size;
		margin: 0 auto;
		color: #fff;
		padding: 0 20px;
		.name {
			font-family: $header-font;
			font-size: 3.5em;
			line-height: 1;
			color: #fff;
			text-align: right;
			@include mq($from: tablet, $until:wide) {
				font-size: 2.8em;
			}
			@include mq($from: mobile, $until:tablet) {
				font-size: 2em;
			}
			@include mq($until:mobile) {
				font-size: 1.5em;
			}
		}

		.description {
			font-size: 1.7em;
			line-height: 1.4;
			color: #fff;
			@include mq($from: mobile, $until:tablet) {
				font-size: 1.3em;
				line-height: 1.3;
			}
			@include mq($until:mobile) {
				font-size: 1.1em;
				line-height: 1.1;
			}
		}

		@include mq($until: mobile) {
			display: block;
			.name {
				text-align: left;
				padding: .75em 0 .5em 0;
				&:first-of-type {
					padding-top: 0;
				}
			}
		}
	}
}