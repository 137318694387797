.animated-in {
	opacity: 0;
}

.home-top-container {
	position: relative;
	display: block;
	overflow: visible;
	background: #fff;
	.text-container {
		position: relative;
		z-index: 99;
	}
	.svg-element {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 120px;
		left: 0;
		z-index: 3;
		display: flex;
		svg {
			width: 35%;
		}
		@include mq($until: tablet) {
			top: 0;
			svg {
				width: 65%;
			}
		}
		@include mq($from: tablet, $until: desktop) {
			top: 60px;
			svg {
				width: 45%;
			}
		}
		@include mq($from: desktop, $until: wide) {
			svg {
				width: 40%;
			}
		}
	}
	.fire-mountain {
		align-items: flex-end;
		justify-content: flex-start;
	}
	.nav-mountain {
		align-items: flex-end;
		justify-content: flex-end;
	}
}