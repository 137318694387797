.slider-title-controller {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.prev, .next {
		font-weight: 400;
		padding: 10px;
		&:hover {
			cursor: pointer;
			color: $green-600;
		}
	}
}

.case-study-container {
	background: $gray-700;
	padding-bottom: 2em;
}

.case-study {
	margin: 0 10px 10px;
	display: flex;
	flex-wrap: nowrap;
	padding: 3em;
	border-radius: $border-radius;
	box-shadow: 2px 2px 12px 2px rgba(0,0,0,.2);
	overflow: visible;
	text-shadow: 2px 2px 2px rgba(0,0,0,.1);
	align-items: stretch;
	.info {
		padding-right: 1.5em;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		.tags {
			display: block;
			text-shadow: none;
			align-self: flex-start;
			li {
				list-style-type: none;
				display: inline-block;
				padding: .8em 1.4em;
				background: transparentize($gray-100, .3);
				font-size: .7em;
				text-transform: uppercase;
				border-radius: $border-radius; 
				margin-right: .25em;
				font-weight: 400;
				letter-spacing: 1px;
				color: $gray-700;
				font-weight: bold;
				&:last-of-type {
					margin-right: 0;
				}
			}
		}
		h3 {
			font-family: $font-1;
			font-weight: 400;
			font-size: 1.8em;
			color: #fff;
			margin: .5em 0;
		}
		a {
			color: #fff;
			font-size: 1.1em;
			&:hover {
				font-size: 1.2em;
			}
		}
	}
	.preview {
		min-width: 50%;
		width: 50%;
		img {
			max-width: 100%;
		}
	}
	@include mq($from: tablet, $until: desktop) {
		padding: 2em;
		.info {
			h3 {
				font-size: 1.5em;
			}
		}
		.preview {
			min-width: 40%;
			width: 40%;
		}
	}
	@include mq($from: mobile, $until: tablet) {
		padding: 2em;
		max-width: 80%;
		.info {
			h3 {
				font-size: 1.5em;
			}
		}
		.preview {
			min-width: 40%;
			width: 40%;
		}
	}
	@include mq($until: mobile) {
		padding: 10px;
		max-width: 70%;
		margin: 0 5px 10px;
		flex-wrap: wrap;
		flex-direction: column-reverse;
		position: relative;
		.preview {
			width: 100%;
		}
		.info .tags {
			position: absolute;
			bottom: 10px;
			left: 10px;
			li {
				margin-right: 3px;
				padding: 5px 6px;
				font-size: .5em;
			}
		}
		.info {
			padding: 5px;
			h3 {
				font-size: 1.3em;
			}
			margin-bottom: 40px;
			a {
				font-size: 1em;
			}
		}
	}
}