.overview {
  background: $gray-700;
  padding-bottom: 2em;
  @include mq($until: mobile) {
    padding-bottom: 0;
  }
  .section-title {
    padding-bottom: 0;
    @include mq($until: desktop) {
      padding-bottom: 1em;
    }
  }
  .cd-container {
    width: 90%;
    max-width: $container-size;
    margin: 0 auto;
    background: $gray-700;
    padding: 0;
    @include mq($until: mobile) {
      width: 100%;
    }
  }
  .cd-container::after {
    content: '';
    display: table;
    clear: both;
  }

  /* -------------------------------- 

  Main components 

  -------------------------------- */

  #cd-timeline {
    position: relative;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  #cd-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
    width: 4px;
    background: #fff;
  }
  @include mq($from: desktop) {
    #cd-timeline {
      margin-top: 1em;
      padding-top: 2em;
      margin-bottom: 3em;
    }
    #cd-timeline::before {
      left: 50%;
      margin-left: -2px;
    }
  }

  .cd-timeline-block {
    position: relative;
    margin: 1em 0;
    &.full-width {
      margin: 4em 0;
      @include mq($until: mobile) {
        margin: 1em 0;
      }
    }
  }
  .cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }

  .cd-timeline-img {
    position: absolute;
    top: 8px;
    left: 12px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  }
  .cd-timeline-img {
    background: $green-600;
  }
  @include mq($from: desktop) {
    .cd-timeline-img {
      width: 30px;
      height: 30px;
      left: 50%;
      margin-left: -15px;
      margin-top: 15px;
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
    }
  }

  .cd-timeline-content {
    position: relative;
    margin-left: 60px;
    margin-right: 30px;
    background: #fff;
    border-radius: 2px;
    padding: 1em;
    &.full-width {
      width: 80%;
      float: none !important;
      margin: 0 auto;
      padding: 0;
      border-bottom: 8px solid $green-600;
      &::before {
        border: none !important;
      }
      @include mq($until: mobile) {
        width: 100%;
      }

      .homepage-form-container {
        background: 0;
        padding: 2em;
        margin: 0 auto;
        align-items: center;
      }
    }
  }
  .cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
  }
  .cd-timeline-content h2 {
    color: $black;
    margin-top:0;
    margin-bottom: 0px;
  }
  .cd-timeline-content p, .cd-timeline-content {
    color: $black;
  }
  .cd-timeline-content p {
    margin: .5em 0 0;
    line-height: 1.6;
  }

  .cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #fff;
  }

  @include mq($from: tablet) {
    .cd-timeline-content h2 {
      font-size: 1.5em;
    }
    .cd-timeline-content p {
      font-size: 1.3em;
    }
  }
  @include mq($from: desktop) {
    .cd-timeline-content {
      color: white;
      margin-left: 0;
      padding: 1.6em;
      width: 40%;
      margin: 0;
    }
    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: #fff;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #fff;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
      float: right;
    }
  }
}
